import { axios } from '@/utils/request'

// 查询当前用户部门层级
export function getCurUserDepartTree(parameter) {
    return axios({
        url: '/sys/sysDepart/getDepartTreeWithPlatform',
        method: 'post',
        data: parameter
    })
}

// 查询作品列表
export function getProductList(parameter) {
    return axios({
        url: '/production/queryProduct',
        method: 'post',
        data: parameter
    })
}

// 删除平台
export function deletePlatform(parameter) {
    return axios({
        url: '/platform/delete_batch',
        method: 'delete',
        params: parameter
    })
}

// 删除作品
export function deleteProduct(parameter) {
    return axios({
        url: '/production/delete_batch',
        method: 'delete',
        params: parameter
    })
}